import * as React from "react"
import Helmet from "react-helmet"

import Layout from '../components/layout.js'
import Contact from '../components/contact.js'

const NotFound = () => {
  return (
    <Layout>

    <Helmet>
      <title>404 Not Found | Billy Dawson</title>
    </Helmet>

      <div className="big-header red">
        <div className="wrap container">
          <div className="row">
            <div className="col-xs-11 col-sm-8 col-md-6 col-lg-5">
              <h1>Oops... Page Not Found 🤌🏻</h1>
              <p>Sorry about that, looks like you've found a sneaky broken link.</p>
              <p><a href="/">Back home</a></p>
            </div>
          </div>
        </div>
      </div>


      <Contact colour="yellow" />

    </Layout>
  )
}

export default NotFound
